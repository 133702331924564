import React, { useState, useEffect } from "react";
import { Input, Button, Form } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import { gqlBuilderWhere, helperFunc } from "utils";
import DealerForms from '../../forms';
import Icons from 'components/icons';
import { DrawerStandart, Localize } from "components/service";

const { Search } = Input;



const DealerTableFilter = ({
    setFilters = () => { },
    setResetSorting = () => { },
    dealerFilters,
    filters,
    setSearchText,
    objOrderBy }) => {

    const [ form ] = Form.useForm();

    const [ filterList, setFilterList ] = useState(null);
    const [ count, setCount ] = useState();

    let filterCount = helperFunc.countFilterList(count);


    /*eslint-disable */
    useEffect(() => {

        if (filters)
        {
            localStorage.setItem("dealerFilters", JSON.stringify({ ...dealerFilters, ...filterList }));
        } else form.resetFields([ 'CITY', 'STREET', 'ZIP_CODE', 'REGION', 'DISTRIC' ]);


    }, [ filters ]);


    useEffect(() => {

        if (dealerFilters)
        {
            setFilters(gqlBuilderWhere(dealerFilters));
            setCount(dealerFilters);
        }

    }, []);
    /*eslint-enable */


    return (
        <>
            <Search
                className="filter-search"
                prefix={ <SearchOutlined /> }
                placeholder={ Localize({ children: "SEARCH.Input_Placeholder_Table_Dealer" }).props.children }
                onChange={ (e) => setSearchText(e.target.value) }
            />

            { setFilters &&
                <Form
                    key="filters-form"
                    className="clear-css-prop table-filter-form"
                    layout="vertical"
                    form={ form }>

                    <DrawerStandart
                        width={ 350 }
                        extraClass={ 'modal-form filters' }
                        modalButton={
                            <Button>
                                <Icons.Filter />
                                <span><Localize>FORMS.Button_Text_ApplyFilters</Localize></span>
                                { filterCount && <span className="filter-count">{ filterCount }</span> }
                            </Button>
                        }
                    >
                        <strong className="modal-title">
                            <Localize>FORMS.Title_Text_Filters</Localize>
                        </strong>

                        <DealerForms.Filter
                            setCount={ setCount }
                            setFilters={ setFilters }
                            setFilterList={ setFilterList }
                            filterList={ filterList }
                            gqlBuilderWhere={ gqlBuilderWhere }
                            dealerFilters={ dealerFilters }

                            form={ form } />

                    </DrawerStandart>

                    <Button
                        type="link"
                        onClick={ () => {
                            setFilterList({});
                            setCount({});
                            setFilters(gqlBuilderWhere({}));
                            setResetSorting(objOrderBy);
                            localStorage.removeItem("dealerFilters");
                            localStorage.removeItem("dealerSorting");
                        } }
                    >
                        <span><Localize>FORMS.Button_Text_ResetFilters</Localize></span>
                    </Button>
                </Form>
            }
        </>
    );
};

export default DealerTableFilter;
