import React, { useState, useEffect } from "react";
import { Input, Button, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { gqlBuilderWhere, helperFunc } from "utils";
import PostForms from "../../forms";
import Icons from "components/icons";
import { DrawerStandart, Localize } from "components/service";

const { Search } = Input;

const PostTableFilter = ({
    setFilters = () => { },
    setFiltersExtra = () => { },
    setResetSorting = () => { },
    postsFilters,
    postsFiltersExtra,
    filters,
    filtersExtra,
    setSearchText,
    modelFilters = "postsFilters",
    modelSorting = "postsSorting",
    resetFields = [ "AUTHOR_ID", "STATUS", "CREATED_AT" ],
    formFieldsHide = [],
    objOrderBy,
}) => {
    const [ form ] = Form.useForm();

    const [ filterList, setFilterList ] = useState(null);
    const [ filterListExtra, setFilterListExtra ] = useState(null);
    const [ count, setCount ] = useState();

    let filterCount = helperFunc.countFilterList(count);

    /*eslint-disable */
    useEffect(() => {
        if (filters)
        {
            localStorage.setItem(
                "postsFilters",
                JSON.stringify({ ...postsFilters, ...filterList })
            );
        } else form.resetFields(resetFields);

        if (filtersExtra)
        {
            localStorage.setItem(
                "postsFiltersExtra",
                JSON.stringify({ ...postsFiltersExtra, ...filterListExtra })
            );
        } else form.resetFields([ "SELLER_NUMBER" ]);
    }, [ filters, filtersExtra ]);

    useEffect(() => {
        if (postsFilters)
        {
            setFilters(gqlBuilderWhere(postsFilters));
        }

        if (postsFiltersExtra)
        {
            setFiltersExtra(gqlBuilderWhere(postsFiltersExtra));
        }

        setCount({ ...postsFilters, ...postsFiltersExtra });
    }, []);
    /*eslint-enable */

    return (
        <>
            <Search
                className="filter-search"
                prefix={ <SearchOutlined /> }
                placeholder={
                    Localize({
                        children: "SEARCH.Input_Placeholder_Table_Title",
                    }).props.children
                }
                onChange={ (e) => setSearchText(e.target.value) }
            />

            <Form
                key="filters-form"
                className="clear-css-prop table-filter-form"
                layout="vertical"
                form={ form }
            >
                <DrawerStandart
                    width={ 350 }
                    extraClass={ "modal-form filters" }
                    modalButton={
                        <Button>
                            <Icons.Filter />
                            <span>
                                <Localize>
                                    FORMS.Button_Text_ApplyFilters
                                </Localize>
                            </span>
                            { filterCount && (
                                <span className="filter-count">
                                    { filterCount }
                                </span>
                            ) }
                        </Button>
                    }
                >
                    <strong className="modal-title">
                        <Localize>FORMS.Title_Text_Filters</Localize>
                    </strong>

                    <PostForms.Filter
                        setCount={ setCount }
                        setFilters={ setFilters }
                        setFiltersExtra={ setFiltersExtra }
                        setFilterList={ setFilterList }
                        setFilterListExtra={ setFilterListExtra }
                        filterList={ filterList }
                        filterListExtra={ filterListExtra }
                        gqlBuilderWhere={ gqlBuilderWhere }
                        postsFilters={ postsFilters }
                        postsFiltersExtra={ postsFiltersExtra }
                        modelFilters={ modelFilters }
                        formFieldsHide={ formFieldsHide }
                        form={ form }
                    />
                </DrawerStandart>

                <Button
                    type="link"
                    onClick={ () => {
                        setCount({});
                        setResetSorting(objOrderBy);
                        setFilters(gqlBuilderWhere({}));
                        setFiltersExtra(gqlBuilderWhere({}));
                        setFilterList({});
                        setFilterListExtra({});
                        localStorage.removeItem("postsFiltersExtra");
                        localStorage.removeItem(modelFilters);
                        localStorage.removeItem(modelSorting);
                    } }
                >
                    <span>
                        <Localize>FORMS.Button_Text_ResetFilters</Localize>
                    </span>
                </Button>
            </Form>
        </>
    );
};

export default PostTableFilter;
