import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { GET_POSTS } from "graphql/query/post-gql";
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import Post from "components/post";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize, ModalStandard } from "components/service";
import { gqlBuilderWhere } from "utils";
import "./post.scss";

const basePath = Post.Const.basePath;
const tableHelper = Post.Helpers.TableMain();

const PostsPage = () => {
    useActiveMenuItem([ "posts" ], [ "posts" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Posts" }).props
                .children,
            path: `${ basePath }`,
        },
    ];

    useBreadCrumbs(breadcrumbs);

    let match = useRouteMatch(`${ basePath }/page/:pageNum`);

    const objOrderBy = [ { column: "CREATED_AT", order: "DESC" } ];

    const [ searchText, setSearchText ] = useState("");
    const [ filters, setFilters ] = useState();
    const [ filtersExtra, setFiltersExtra ] = useState();
    const [ resetSorting, setResetSorting ] = useState(false);

    const postsFiltersExtra = JSON.parse(
        localStorage.getItem("postsFiltersExtra")
    );
    const postsFilters = JSON.parse(localStorage.getItem("postsFilters"));
    const postsSorting = JSON.parse(localStorage.getItem("postsSorting"));

    const objectFilters = filters || gqlBuilderWhere(postsFilters);
    const objectFilterExtra =
        filtersExtra || gqlBuilderWhere(postsFiltersExtra);

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Posts" }).props
                .children,
            path: "posts",
        },

        {
            label: Localize({ children: "NAVBAR_PAGES.Label_AllComments" })
                .props.children,
            path: "all-comments",
        },
    ];

    return (
        <PageWrapDefault
            className="page-posts"
            dataExist={ true }
            title={ <Localize>PAGES.Title_Posts</Localize> }
            pageNavbar={ pageNavbar }
            staticPath=""
        >
            <Tables.Main
                model="posts"
                extraClass="table-posts"
                query={ GET_POSTS }
                varSet={ {
                    perPage: 30,
                    whereAuthor: objectFilterExtra,
                } }
                routeUrl={ `${ basePath }` }
                currentPage={ match ? parseInt(match.params.pageNum) : 1 }
                searchText={ searchText }
                objFilter={ objectFilters }
                objOrderBy={ postsSorting || objOrderBy }
                sortingName="postsSorting"
                tableHelper={ tableHelper }
                resetSorting={ resetSorting }
                totalCounter={ true }
            >
                <div className="table-action-bar">
                    <div className="col">
                        <Post.Filters.Table
                            filters={ filters }
                            filtersExtra={ filtersExtra }
                            setSearchText={ setSearchText }
                            setFilters={ setFilters }
                            setFiltersExtra={ setFiltersExtra }
                            postsFilters={ postsFilters }
                            postsFiltersExtra={ postsFiltersExtra }
                            setResetSorting={ setResetSorting }
                            objOrderBy={ objOrderBy }
                        />
                    </div>

                    <div className="col">
                        <ModalStandard
                            width={ 380 }
                            extraClass={ "modal-form" }
                            modalButton={
                                <Button type="primary">
                                    <span>
                                        <DownloadOutlined /> &nbsp;
                                        <Localize>
                                            MODAL.Button_Text_PostsExport
                                        </Localize>
                                    </span>
                                </Button>
                            }
                        >
                            <strong className="modal-title">
                                <Localize>FORMS.Title_PostsExport</Localize>
                            </strong>

                            <Post.Forms.Export />
                        </ModalStandard>
                    </div>
                </div>
            </Tables.Main>
        </PageWrapDefault>
    );
};

export default PostsPage;
