import React from "react";
import { Form, InputNumber, Input, Button, DatePicker } from "antd";
import { GET_DEALERS_CURSOR } from "graphql/query/dealer-gql";
import UserFormItems from "../fields";
import { Localize } from "components/service";
import { FormElements } from "components/layout";
import { initialValFormatted, initialDateBetween } from "utils/helper-functions";

const { RangePicker } = DatePicker;

const UserFiltersForm = ({
    action,
    usersFilters,
    usersFiltersExtra,
    setCount,
    setFilters,
    setFiltersExtra,
    setFilterList,
    setFilterListExtra,
    filterList,
    filterListExtra,
    gqlBuilderWhere,
    formFieldsHide,
    form,
}) => {
    const onChangeSetFilter = (e, name, value) => {
        if (e)
        {
            name = e.target.name;
            value = e.target.value;
        }

        if (name === "SENT_PASSWORD")
        {
            value.toString().length > 0
                ? setFilterListExtra({
                    ...filterListExtra,
                    [ name ]: {
                        column: name,
                        operator: "EQ",
                        value: value,
                    },
                })
                : setFilterListExtra({ ...filterListExtra, [ name ]: {} });
        } else if (name === "SELLER_NUMBER")
        {
            value.toString().length > 0
                ? setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "LIKE",
                        value: `${ value }%`,
                    },
                })
                : setFilterList({ ...filterList, [ name ]: {} });
        } else if (name === "BRAND")
        {
            value.toString().length > 0
                ? setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "EQ",
                        value: value,
                    },
                })
                : setFilterList({ ...filterList, [ name ]: {} });
        } else if (name === "LAST_VISIT_AT")
        {
            if (value === "yes")
            {
                setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "IS_NOT_NULL",
                    },
                });
            } else if (value === "no")
            {
                setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "IS_NULL",
                    },
                });
            } else
            {
                setFilterList({ ...filterList, [ name ]: { column: undefined } });
            }
        } else
        {
            value.toString().length > 0
                ? setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "EQ",
                        value: value,
                    },
                })
                : setFilterList({ ...filterList, [ name ]: {} });
        }
    };

    return (
        <div className="ant-form ant-form-vertical filters-form">
            { formFieldsHide.includes("SELLER_NUMBER") ? null : (
                <Form.Item
                    initialValue={ initialValFormatted(
                        usersFilters,
                        "SELLER_NUMBER"
                    ) }
                    name="SELLER_NUMBER"
                    label={ <Localize>FORMS.Input_Label_SellerNumber</Localize> }
                    onChange={ onChangeSetFilter }
                >
                    <InputNumber
                        placeholder={
                            Localize({
                                children: "FORM_RULES.Required_SellerNumber",
                            }).props.children
                        }
                        name="SELLER_NUMBER"
                        min={ 3 }
                        max={ 1000000 }
                        controls={ false }
                        style={ {
                            width: "100%",
                            border: "1px solid rgba(34, 43, 51, 0.15)",
                        } }
                    />
                </Form.Item>
            ) }

            { formFieldsHide.includes("BRAND") ? null : (
                <Form.Item
                    initialValue={ initialValFormatted(usersFilters, "BRAND") }
                    name="BRAND"
                    label={ <Localize>FORMS.Input_Label_CompanyName</Localize> }
                    onChange={ onChangeSetFilter }
                >
                    <Input
                        placeholder={
                            Localize({
                                children: "FORM_RULES.Required_CompanyName",
                            }).props.children
                        }
                        name="BRAND"
                        className="fw-placeholder-600"
                    />
                </Form.Item>
            ) }

            { formFieldsHide.includes("DEALER_ID") ? null : (
                <FormElements.RadioSelect
                    skipQuery={ !usersFilters }
                    location="filter"
                    form={ form }
                    query={ GET_DEALERS_CURSOR }
                    model="dealersCursor"
                    name="DEALER_ID"
                    label="FORMS.Input_Label_Dealer"
                    modalTitle="FORMS.Title_ChooseDealer"
                    modelCursorLabel={ [ "company_name", "dealer_number" ] }
                    styleLabel={ { fontWeight: 700 } }
                    initialValue={
                        usersFilters?.DEALER_ID?.value
                            ? [ { value: usersFilters?.DEALER_ID?.value } ]
                            : false
                    }
                    onChangeSetFilter={ onChangeSetFilter }
                />
            ) }

            { formFieldsHide.includes("SENT_PASSWORD") ? null : (
                <UserFormItems.SentPasswordSelect
                    initialValue={ initialValFormatted(
                        usersFiltersExtra,
                        "SENT_PASSWORD"
                    ) }
                    name="SENT_PASSWORD"
                    form={ form }
                    onChangeSetFilter={ onChangeSetFilter }
                />
            ) }

            { formFieldsHide.includes("STATUS") ? null : (
                <UserFormItems.StatusSelect
                    initialValue={ initialValFormatted(usersFilters, "STATUS") }
                    name="STATUS"
                    form={ form }
                    onChangeSetFilter={ onChangeSetFilter }
                />
            ) }

            { formFieldsHide.includes("DIAMOND_STATUS") ? null : (
                <UserFormItems.DiamondStatusSelect
                    initialValue={ usersFilters?.DIAMOND_STATUS?.value }
                    name="DIAMOND_STATUS"
                    form={ form }
                    onChangeSetFilter={ onChangeSetFilter }
                />
            ) }

            { formFieldsHide.includes("ROLE") ? null : (
                <UserFormItems.RoleSelect
                    initialValue={ initialValFormatted(usersFilters, "ROLE") }
                    name="ROLE"
                    form={ form }
                    onChangeSetFilter={ onChangeSetFilter }
                />
            ) }

            { formFieldsHide.includes("LAST_VISIT_AT") ? null : (
                <UserFormItems.VisitedSelect
                    initialValue={ initialValFormatted(
                        usersFilters,
                        "LAST_VISIT_AT"
                    ) }
                    name="LAST_VISIT_AT"
                    form={ form }
                    onChangeSetFilter={ onChangeSetFilter }
                />
            ) }

            { formFieldsHide.includes("CREATED_AT") ? null : (
                <Form.Item
                    initialValue={ initialDateBetween(usersFilters, "CREATED_AT") }
                    name="CREATED_AT"
                    label={ <Localize>FORMS.Input_Label_CreatedAt</Localize> }
                >
                    <RangePicker
                        separator="&ndash;"
                        format="DD.MM.YYYY"
                        placeholder={ [
                            Localize({ children: "FORMS.Input_Placeholder_StartDate" }).props
                                .children,
                            Localize({ children: "FORMS.Input_Placeholder_EndDate" }).props
                                .children,
                        ] }
                        onChange={ (dates, dateStrings) =>
                            dates
                                ? setFilterList({
                                    ...filterList,
                                    CREATED_AT: {
                                        column: "CREATED_AT",
                                        operator: "BETWEEN",
                                        value: dates.map(date => date.format('YYYY-MM-DD')),
                                    },
                                })
                                : setFilterList({
                                    ...filterList,
                                    CREATED_AT: {},
                                })
                        }
                    />
                </Form.Item>
            ) }

            <div className="form-btn-holder">
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={ () => {
                        setFilters(
                            gqlBuilderWhere({ ...usersFilters, ...filterList })
                        );
                        setFiltersExtra(
                            gqlBuilderWhere({
                                ...usersFiltersExtra,
                                ...filterListExtra,
                            })
                        );
                        setCount({
                            ...usersFilters,
                            ...filterList,
                            ...usersFiltersExtra,
                            ...filterListExtra,
                        });
                        action();
                    } }
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>
                <Button
                    className="ant-btn-secondary bg-light-gray btn-right"
                    htmlType="submit"
                    onClick={ () => {
                        setCount({});
                        setFilterList({});
                        setFilterListExtra({});
                        setFilters(gqlBuilderWhere({}));
                        setFiltersExtra(gqlBuilderWhere({}));
                        localStorage.removeItem("usersFilters");
                        localStorage.removeItem("usersSorting");
                        localStorage.removeItem("usersFiltersExtra");
                    } }
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>
            </div>
        </div>
    );
};

export default UserFiltersForm;
